import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', name: 'LoginView', component: () => import('../views/LoginView.vue') },
  { path: '/home', name: 'HomeView', component: () => import('../views/HomeView.vue') },
  { path: '/games/aviator_hz', name: 'AviatorGame', component: () => import('../views/games/aviator_hz.vue') },
  { path: '/games/head_tail', name: 'head_tail', component: () => import('../views/games/head_tail.vue') },
  { path: '/games/number_slot', name: 'number_slot', component: () => import('../views/games/number_slot.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Add navigation guard
router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem('access_token');
  const publicPages = ['/'];
  const authPage = publicPages.includes(to.path);

  if (accessToken && authPage) {
    next('/home');
  } else {
    next();
  }
});

export default router;
